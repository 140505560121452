<template>
  <div class="user">
    <div class="container">
      <div class="form_info">
        <el-form ref="form" :model="queryInfo" label-width="80px">
          <div class="form">
            <div class="comble_txt">
              <el-form-item style="margin: 0 0 12px 0;" label="活动状态:" prop="">
                <el-radio-group v-model="queryInfo.activityState" @change="search">
                  <el-radio-button
                    :label="item.id"
                    v-for="(item, index) in InformationTypes"
                    :key="index"
                    >{{ item.value }}</el-radio-button
                  >
                </el-radio-group>
              </el-form-item>
              <el-form-item style="margin: 0 0 12px 0;" label="参与方式:" prop="">
                <el-radio-group v-model="queryInfo.activityType" @change="search">
                  <el-radio-button
                    :label="item.id"
                    v-for="(item, index) in ParticipationMode"
                    :key="index"
                    >{{ item.value }}</el-radio-button
                  >
                </el-radio-group>
              </el-form-item>
              <el-form-item style="margin: 0 0 12px 0;" label="活动日期:">
                <el-date-picker
                  value-format="yyyy-mm-dd"
                  v-model="ruleForm.date"
                  @change="checkParamsData"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item style="margin: 0 0 12px 0;" label="所在地区:" prop="">
                <el-select
                  v-model="queryInfo.activityProvince"
                  placeholder="请选择省份"
                  @change="citychange"
                >
                  <el-option :value="''" label="全部"></el-option>
                  <el-option
                    v-for="item in operatingList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
                <div style="display: inline-block; width: 10px"></div>
                <el-select
                  v-model="queryInfo.activityCity"
                  placeholder="请选择市"
                  @change="districtchange"
                >
                  <el-option :value="''" label="全部"></el-option>
                  <el-option
                    v-for="item in operatingLists"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
                <div style="display: inline-block; width: 10px"></div>
                <el-select
                  v-model="queryInfo.activitydistrict"
                  placeholder="请选择区"
                  @change="search"
                >
                  <el-option :value="''" label="全部"></el-option>
                  <el-option
                    v-for="item in districtList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label=" "
                label-width="0px"
                style="border-top: 1px dashed #efefef; padding-top: 12px; margin-bottom: 0"
              >
                <div class="input-with">
                  <div style="display: flex">
                    <el-button type="primary" v-if="$hasPerms('activity:add')" @click="addactivity"
                      >新增活动</el-button
                    >

                    <el-button
                      v-if="$hasPerms('activity:updown')"
                      style="margin-right: 5px"
                      @click="updateOnShelf"
                      >批量发布</el-button
                    >
                    <el-button
                      v-if="$hasPerms('activity:updown')"
                      style="margin-right: 5px"
                      @click="updateOffShelf"
                      >批量下架</el-button
                    >
                    <div style="position: relative; top: 5px; left: 10px">
                      <el-checkbox
                        :indeterminate="isIndeterminate"
                        v-model="checkAll"
                        @change="handleCheckAllChange"
                        >全选</el-checkbox
                      >
                    </div>
                  </div>
                  <div>
                    <el-input
                      placeholder="活动名称、主办方、所在城市"
                      v-model="queryInfo.fuzzyQuery"
                      class="input-with-select"
                    >
                      <el-button class="seach" slot="append" @click="search()">搜索</el-button>
                    </el-input>
                  </div>
                </div>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
      <div
        class="tab_txt"
        v-loading="loading"
        element-loading-text="加载中..."
        element-loading-spinner="el-icon-loading"
      >
        <div style="display: flex; align-items: center; padding-bottom: 10px">
          <div style="padding-right: 50px">
            <span>当前条件下共查出{{ total }}篇活动</span>
          </div>
          <div style="display: flex; align-items: center; margin-right: 30px">
            {{ orderList[3].name }}
            <div class="main">
              <span
                class="arrUp"
                @click="clickUp1(orderList[3], 3)"
                :style="{ 'border-bottom-color': orderList[3].color1 }"
              ></span>
              <span
                class="arrDown"
                @click="clickDown1(orderList[3], 3)"
                :style="{ 'border-top-color': orderList[3].color2 }"
              ></span>
            </div>
          </div>
          <!-- <div
          v-for="(item, index) in orderList"
          :key="index"
          style="display: flex; align-items: center; margin-right: 30px"
        >
          {{ item.name }}
          <div class="main">
            <span
              class="arrUp"
              @click="clickUp1(item, index)"
              :style="{ 'border-bottom-color': item.color1 }"
            ></span>
            <span
              class="arrDown"
              @click="clickDown1(item, index)"
              :style="{ 'border-top-color': item.color2 }"
            ></span>
          </div>
        </div> -->
        </div>
        <div class="storeCenter_item" v-for="(item, index) in listData" :key="index">
          <div class="item_checkbox">
            <el-checkbox
              @change="latechange($event, item)"
              v-model="item.checked1"
              label=""
            ></el-checkbox>
          </div>
          <div class="storeCenter_item_top">
            <div class="storeCenter_item_top_left">
              <div class="banner_box">
                <div
                  class="activity_state"
                  :class="['not_started', 'have_in_hand', 'closed'][item.activityState - 1]"
                >
                  {{ ['未开始', '进行中', '已结束'][item.activityState - 1] }}
                </div>
                <img v-if="item.activityImg" :src="item.activityImg" alt="" />
                <img v-else src="../../../assets/image/Group 3237.png" alt="" />
              </div>
              <div class="storeCenter_item_top_left_flex">
                <div style="display: flex; align-items: center">
                  <h5
                    style="
                      display: inline-block;
                      margin: 0;
                      font-size: 18px;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-line-clamp: 1;
                      width: 200px;
                      -webkit-box-orient: vertical;
                    "
                  >
                    {{ item.activityName }}
                  </h5>

                  <div class="mark_box">
                    <div v-if="item.isPlatform == 1">
                      <img src="../../../assets/image/Group 3298.png" alt="" /><span>平台活动</span>
                    </div>
                    <div v-else>
                      <img src="../../../assets/image/展厅-5 3.png" alt="" /><span>渠道活动</span>
                    </div>
                    <div v-if="item.isMoney">
                      <img src="../../../assets/image/Vector-1.png" alt="" /><span>免费</span>
                    </div>
                    <div v-else>
                      <img src="../../../assets/image/Vector-1.png" alt="" /><span>付费</span>
                    </div>
                    <div v-if="item.activityType == 1">
                      <img src="../../../assets/image/Vector.png" alt="" /><span>线上</span>
                    </div>
                    <div v-else-if="item.activityType == 2">
                      <img src="../../../assets/image/Vector(1).png" alt="" /><span>线下</span>
                    </div>
                  </div>
                </div>

                <div style="margin-top: 10px">
                  <span style="padding-left: 0px">
                    所在地区： {{ item.activityProvince }}-{{ item.activityCity }}</span
                  >
                </div>
                <p class="address">
                  <span> 活动时间： {{ item.activityStartTime }}至{{ item.activityEndTime }}</span>
                </p>
              </div>
            </div>
            <div class="storeCenter_item_top_center">
              <div class="count_box" @click="toapplication(item)">
                <div>{{ item.signUpCount }}</div>
                <div>报名</div>
              </div>
              <!-- <div class="count_box" @click="clickount(item)">
              <div>{{ item.checkCount }}</div>
              <div>查看</div>
            </div> -->
              <!-- <div class="count_box" @click="shareCount(item)">
              <div>{{ item.shareCount }}</div>
              <div>分享</div>
            </div> -->
            </div>

            <div class="storeCenter_item_top_right">
              <div class="operation_area">
                <div v-if="$hasPerms('activity:updown')">
                  <span
                    v-if="item.isShelf == 1 || item.isShelf == null"
                    class="release"
                    @click="OnShelf(item)"
                    >发布</span
                  >
                </div>
                <div v-if="$hasPerms('activity:updown')">
                  <span v-if="item.isShelf == 0" class="shelf" @click="OffShelf(item)">下架</span>
                </div>
                <div v-if="$hasPerms('activity:edit')">
                  <span v-if="item.activityChannel == 2" class="edit" @click="editActivity(item)"
                    >编辑</span
                  >
                </div>
                <div v-if="$hasPerms('activity:freeze')">
                  <span v-if="item.isDelete == 0" class="frozen" @click="delcompany(item)"
                    >冻结</span
                  >
                  <span v-if="item.isDelete == 1" class="frozen" @click="cancelcompany(item)"
                    >激活</span
                  >
                </div>
              </div>
              <div class="details">操作人：{{ item.userName }}</div>
              <div class="details">最近操作时间：{{ item.updateTime }}</div>
              <div class="po_imgqr" @click="getQRCode(item)">
                <img
                  src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img/Group%204010.png"
                  alt=""
                />
              </div>
            </div>
            <div class="pore_qr" @mouseleave="item.qrvisible = !item.qrvisible">
              <el-popover
                placement="left"
                title=""
                width="139"
                height="200"
                trigger="manual"
                v-model="item.qrvisible"
              >
                <div class="qr_top">
                  <span>签到二维码</span>
                </div>
                <div class="popover_img">
                  <img :src="QRCode" alt="" />
                </div>
                <div
                  style="
                    padding: 5px 0px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <el-button plain size="mini" @click="imgClick(item, QRCode)">导出图片</el-button>
                </div>
              </el-popover>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="new_page" v-if="listData.length">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import {
  activitylist,
  updateOffShelf,
  getQRCode,
  cancelFreezeActivity,
  freezeActivity,
  updateOnShelf
} from '@/api/activity'
import { getCityAll } from '@/api/demand'
const defaultQueryInfo = Object.freeze({
  pageNum: 1,
  pageSize: 10,
  partnerChannelId: '', //合作伙伴编号
  schemaName: '', //智参中心schema值
  activityCity: null, //活动城市
  activityEndTime: null, //活动结束时间
  activityProvince: null, //活动省份
  activitydistrict: null,
  activityStartTime: null, //活动开始时间
  activityState: null, //活动状态（1：未开始；2：进行中；3：已结束）
  activityType: null, //活动形式，1线上，2线下
  orders: '1', //排序 默认传1 2->查看次数升序;3->查看次数降序;4->查看人数升序;5->查看人数降序;6->分享人数升序;7->分享人数降序;8->报名人数升序;9->报名人数降序
  fuzzyQuery: null //模糊查询（活动名称、主办方、所在城市）
})
export default {
  name: 'activity',
  data() {
    return {
      InformationTypes: [
        {
          value: '全部',
          id: null
        },
        {
          value: '未开始',
          id: '1'
        },
        {
          value: '进行中',
          id: '2'
        },

        {
          value: '已结束',
          id: '3'
        }
      ],
      ParticipationMode: [
        {
          value: '全部',
          id: null
        },
        {
          value: '线上',
          id: '1'
        },
        {
          value: '线下',
          id: '2'
        }
      ],
      orderList: [
        {
          name: '按查看次数',
          color1: '',
          color2: '#C6C6C6',
          asc: 2,
          desc: 3
        },
        {
          name: '按查看人数',
          color1: '',
          color2: '#C6C6C6',
          asc: 4,
          desc: 5
        },
        {
          name: '按分享人数',
          color1: '',
          color2: '#C6C6C6',
          asc: 6,
          desc: 7
        },
        {
          name: '按报名人数',
          color1: '',
          color2: '#C6C6C6',
          asc: 8,
          desc: 9
        }
      ], //排序
      QRCode: '', //活动签到二维码
      checkAll: false, //全选
      isIndeterminate: false,
      ruleForm: {
        date: null
      },
      rules: {
        date: [{ required: true, message: '请选择账号周期', trigger: 'blur' }]
      },
      ids: [],
      idsList: [],
      idof: [], //勾选
      queryInfo: { ...defaultQueryInfo },
      listData: [],
      // 遮罩层
      loading: false,
      // 总条数
      total: 0,
      parteninfo: JSON.parse(sessionStorage.getItem('typeMode')),

      operatingList: [], //城市
      operatingLists: [], //省份
      districtList: [] //区
    }
  },
  created() {
    this.search()
    this.incity()
  },
  methods: {
    //活动一览
    async search() {
      this.queryInfo.partnerChannelId = this.parteninfo.id
      this.queryInfo.schemaName = this.parteninfo.schemaName
      this.loading = true
      const res = await activitylist(this.queryInfo)
      if (res.data.resultCode == 200) {
        this.listData = res.data.data.list
        this.listData.forEach((item) => {
          this.$set(item, 'checked1', false)
        })
        this.total = res.data.data.total
        this.loading = false
      }
    },
    //城市
    async incity() {
      const res = await getCityAll()
      if (res.data.resultCode == 200) {
        this.operatingList = res.data.data
      }
    },
    //选择城市
    citychange(item) {
      console.log(item)
      this.operatingList.forEach((o) => {
        if (this.queryInfo.activityProvince == o.name) {
          this.queryInfo.activityCity = ''
          if (item == '全部') {
            this.operatingLists = this.operatingList.map((el) => el.cityResults).flat(Infinity)
          } else {
            let isnum = item.indexOf('市')
            if (isnum == -1) {
              this.operatingLists = this.operatingList.find((el) => el.name == item).cityResults
            } else {
              this.queryInfo.activityCity = item
              this.districtList = this.operatingList.find((el) => el.name == item).cityResults
            }
          }
        }
      })
      this.search()
    },
    //选择区
    districtchange(item) {
      this.operatingLists.forEach((o) => {
        if (this.queryInfo.activityCity == o.name) {
          this.queryInfo.activitydistrict = ''
          if (item == '全部') {
            this.districtList = this.operatingLists.map((el) => el.cityResults).flat(Infinity)
          } else {
            this.districtList = this.operatingLists.find((el) => el.name == item).cityResults
          }
        }
      })
      this.search()
    },
    //获取签到二维码
    async getQRCode(item) {
      const query = {
        partnerChannelId: this.parteninfo.id,
        sceneStr: `/pages/activity/activityDetail/index?id=${item.id}&code=${true}`
      }

      const res = await getQRCode(query)
      if (res.data) {
        this.loading = false
        let blob = new Blob([res.data])
        let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
        const a = document.createElement('a') //创建a标签
        a.style.display = 'none'
        console.log(href)
        a.href = href // 指定下载'链接
        this.QRCode = href
        item.qrvisible = true
        // a.download = `${item.activityName}.png` //指定下载文件名
        // // a.download = "test.png";
        // a.click() //触发下载
        // window.URL.revokeObjectURL(a.href) //释放URL对象
        // if (document.body.contains(a)) {
        //   document.body.removeChild(a) //释放标签
        // }
      } else {
        this.$message.error('请求失败!')
      }
    },
    //下载二维码
    imgClick(item, href) {
      const a = document.createElement('a') //创建a标签
      a.style.display = 'none'
      console.log(href)
      a.href = href // 指定下载'链接
      this.QRCode = href
      item.qrvisible = true
      a.download = `${item.activityName}.png` //指定下载文件名
      // a.download = "test.png";
      a.click() //触发下载
      window.URL.revokeObjectURL(a.href) //释放URL对象
      if (document.body.contains(a)) {
        document.body.removeChild(a) //释放标签
      }
    },
    // 切换搜索列表的参数
    checkParams(key, val) {
      this.queryInfo[key] = val
      this.search()
    },
    checkParamsData(val) {
      if (val) {
        this.queryInfo.activityStartTime = val[0]
        this.queryInfo.activityEndTime = val[1]
      } else {
        this.queryInfo.activityStartTime = null
        this.queryInfo.activityEndTime = null
      }

      this.search()
    },

    // 多选框选中数据
    handleSelectionChange(selection) {
      this.idgisen = selection.map((item) => item.schemaName)
    },
    //新增活动
    addactivity() {
      this.$router.push({
        name: 'addactivity'
      })
    },
    //编辑活动
    editActivity(item) {
      this.$router.push({
        name: 'addactivity',
        query: { id: item.id }
      })
    },
    //报名管理
    toapplication(item) {
      this.$router.push({
        name: 'accountManagement',
        query: { id: item.id, activityChannel: item.activityChannel }
      })
    },
    //查看人数
    clickount(item) {
      this.$router.push({
        name: 'checkCount',
        query: { id: item.id }
      })
    },
    //查看分享人数
    shareCount(item) {
      this.$router.push({
        name: 'shareCount',
        query: { id: item.id }
      })
    },
    //升序排序
    clickUp1(item, index) {
      ;(this.orderList = [
        {
          name: '按查看次数',
          color1: '',
          color2: '#C6C6C6',
          asc: 2,
          desc: 3
        },
        {
          name: '按查看人数',
          color1: '',
          color2: '#C6C6C6',
          asc: 4,
          desc: 5
        },
        {
          name: '按分享人数',
          color1: '',
          color2: '#C6C6C6',
          asc: 6,
          desc: 7
        },
        {
          name: '按报名人数',
          color1: '',
          color2: '#C6C6C6',
          asc: 8,
          desc: 9
        }
      ]), //排序
        (this.queryInfo.orders = item.asc)
      this.search()
      this.orderList[index].color1 = '#0099ff'
      this.orderList[index].color2 = ''
    },
    //降序排序
    clickDown1(item, index) {
      ;(this.orderList = [
        {
          name: '按查看次数',
          color1: '',
          color2: '#C6C6C6',
          asc: 2,
          desc: 3
        },
        {
          name: '按查看人数',
          color1: '',
          color2: '#C6C6C6',
          asc: 4,
          desc: 5
        },
        {
          name: '按分享人数',
          color1: '',
          color2: '#C6C6C6',
          asc: 6,
          desc: 7
        },
        {
          name: '按报名人数',
          color1: '',
          color2: '#C6C6C6',
          asc: 8,
          desc: 9
        }
      ]), //排序
        (this.queryInfo.orders = item.desc)
      this.search()
      this.orderList[index].color1 = ''
      this.orderList[index].color2 = '#0099ff'
    },
    //单个下架
    OffShelf(row) {
      if (row.isDelete == 1) {
        this.$message.warning('该活动已冻结')
        return
      }
      const params = [
        {
          foreignKey: row.foreignKey,
          schemaName: this.parteninfo.schemaName
        }
      ]

      this.$alert('是否确认下架活动？')
        .then(function () {
          return updateOffShelf(params)
        })
        .then((res) => {
          this.search()

          if (res.data.data) {
            this.$message.warning(res.data.data)
          } else {
            this.$message.success('下架成功')
          }
        })
        .catch(() => {})
    },
    //单个上架
    OnShelf(row) {
      if (row.isDelete == 1) {
        this.$message.warning('该活动已冻结')
        return
      }
      const params = [
        {
          foreignKey: row.foreignKey,
          schemaName: this.parteninfo.schemaName
        }
      ]
      this.$alert('是否确认上架活动？')
        .then(function () {
          return updateOnShelf(params)
        })
        .then((res) => {
          this.search()

          if (res.data.data) {
            this.$message.warning(res.data.data)
          } else {
            this.$message.success('上架成功')
          }
        })
        .catch(() => {})
    },
    //批量下架
    updateOffShelf() {
      if (this.ids.length > 0) {
        let flag = false
        this.idsList.forEach((el) => {
          if (el.isDelete == 1) {
            flag = true
          }
        })
        if (flag) {
          this.$message.warning('您选择的活动中有已冻结的！')
          return
        }
        const that = this
        this.$alert('是否确认批量下架活动？')
          .then(function () {
            return updateOffShelf(that.ids)
          })
          .then((res) => {
            this.search()
            this.ids = []
            this.isIndeterminate = false
            this.checkAll = false
            if (res.data.data) {
              this.$message.warning(res.data.data)
            } else {
              this.$message.success('下架成功')
            }
          })
          .catch(() => {})
      } else {
        this.$message.warning('请选择活动')
      }
    },
    //批量上架
    updateOnShelf() {
      if (this.ids.length > 0) {
        let flag = false
        this.idsList.forEach((el) => {
          if (el.isDelete == 1) {
            flag = true
          }
        })
        if (flag) {
          this.$message.warning('您选择的活动中有已冻结的！')
          return
        }
        const that = this
        this.$alert('是否确认批量上架活动？')
          .then(function () {
            return updateOnShelf(that.ids)
          })
          .then((res) => {
            this.search()
            this.ids = []
            this.isIndeterminate = false
            this.checkAll = false
            if (res.data.data) {
              this.$message.warning(res.data.data)
            } else {
              this.$message.success('上架成功')
            }
          })
          .catch(() => {})
      } else {
        this.$message.warning('请选择活动')
      }
    },
    //勾选
    latechange(bol, row) {
      const index = this.idof.indexOf(row.foreignKey)
      if (index == -1) {
        this.ids.push({
          foreignKey: row.foreignKey,
          schemaName: this.parteninfo.schemaName
        })
        this.idof.push(row.foreignKey)
        this.idsList.push(row)
      } else {
        this.ids.splice(index, 1)
        this.idof.splice(index, 1)
        this.idsList.splice(index, 1)
      }
      console.log(this.ids)
    },
    //全选
    handleCheckAllChange(val) {
      if (val) {
        this.listData.forEach((item) => {
          this.ids.push({
            foreignKey: item.foreignKey,
            schemaName: this.parteninfo.schemaName
          })
          this.idsList.push(item)
          this.idof.push(item.foreignKey)
          this.$set(item, 'checked1', true)
        })
      } else {
        this.listData.forEach((item) => {
          this.ids = []
          this.idsList = []
          this.idof = []
          this.$set(item, 'checked1', false)
        })
      }
      this.isIndeterminate = val
    },
    //冻结活动
    delcompany(row) {
      const params = {
        foreignKey: row.foreignKey,
        schemaName: this.parteninfo.schemaName
      }

      this.$alert('您是否确认冻结？')
        .then(function () {
          return freezeActivity(params)
        })
        .then(() => {
          this.search()
          this.$message.success('冻结成功')
        })
        .catch(() => {})
    },
    //激活活动
    cancelcompany(row) {
      const params = {
        foreignKey: row.foreignKey,
        schemaName: this.parteninfo.schemaName
      }

      this.$alert('您是否确认激活？')
        .then(function () {
          return cancelFreezeActivity(params)
        })
        .then((res) => {
          if (res.data.resultCode == 200) {
            this.search()
            this.$message.success('激活成功')
          } else {
            this.$message.warning(res.data.message)
          }
        })
        .catch(() => {})
    },

    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-checkbox__inner::after {
  box-sizing: content-box;
  content: '';
  border: 1px solid #fff;
  border-left: 0;
  border-top: 0;
  height: 7px;
  left: 5px;
  position: absolute;
  top: 2px;
  transform: rotate(45deg) scaleY(0);
  width: 4px;
  transition: transform 0.15s ease-in 50ms;
  transform-origin: center;
}
::v-deep .el-checkbox__inner {
  display: inline-block;
  position: relative;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  background-color: #fff;
  z-index: 1;
  transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
    background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
}
.label_list {
  display: flex;
  align-items: center;
  margin-top: 8px;
  .label {
    cursor: pointer;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    margin-right: 15px;
  }
  .acitve_label {
    padding: 0 10px;

    height: 28px;
    color: #146aff;
    background: #cbdeff;
    border-radius: 2px;
  }
}
.Subtag_list {
  padding: 10px;
  min-height: 77px;
  background: #f5f6f7;
  margin-top: 10px;
  display: flex;
  .label_title {
    width: 70px;
    flex-shrink: 0;
    white-space: nowrap;
    // span {
    //   font-family: 'PingFang SC';
    //   font-style: normal;
    //   font-weight: 400;
    //   font-size: 12px;
    //   line-height: 17px;
    //   margin-right: 15px;
    //   color: #7c7f8e;
    // }
  }
  .label_box {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    div {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height */
      margin-right: 10px;
      color: #1f212b;
      // margin-bottom: 10px;
      height: 20px;
      padding: 5px 10px;
    }
    white-space: pre-wrap;
    .acitve_label {
      // height: 28px;
      color: #146aff;
      background: #cbdeff;
      border-radius: 2px;
    }
  }
}
.enterprise_dialog {
  .tip {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #333333;
  }
  .enterprise_box {
    .enterprise_item {
      margin-top: 10px;
      width: 387px;
      padding: 10px;
      background: #f4f4f4;
      border-radius: 1px;
      display: flex;
      justify-content: space-between;
      .left {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        /* identical to box height */

        color: #151515;
      }
      .right {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;

        color: #fd5469;
      }
    }
  }
}
.comble_txt {
  span {
    margin-left: 20px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
  }
}
.user {
  width: 100%;
  overflow: auto;
  .container {
    height: 80vh;
    overflow: auto;
  }
  .form_info {
    background: #ffffff;
    padding: 20px;
  }

  .tab_txt {
    background: #f6f7fb;

    margin-top: 16px;
    padding: 0px;
    padding-bottom: 16px;
    .main {
      display: flex;
      flex-direction: column;
    }

    .arrUp {
      width: 0;
      height: 0;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      border-bottom: 8px solid #c6c6c6;
      cursor: pointer;
    }

    .arrDown {
      width: 0;
      height: 0;
      margin-top: 2px;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      border-top: 8px solid #c6c6c6;
      cursor: pointer;
    }
  }

  .span_div {
    font-size: 14px;
    color: #7c7f8e;
    margin-right: 12px;
  }

  .input-with {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;

    .input-with-select {
      width: 400px;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }

  .in_table {
    width: 100%;
    margin-top: 20px;

    .img_url {
      width: 42px;
      height: 42px;
      border-radius: 4px;
    }
  }

  .new_page {
    margin: 20px auto;
    width: 100%;
    text-align: center;
  }

  .storeCenter_item {
    width: 100%;
    overflow: hidden;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    box-sizing: border-box;
    position: relative;
    .item_checkbox {
      line-height: 36px;
      margin: 0px 10px;
    }
    .Relevancy {
      position: absolute;
      width: 83px;
      height: 25px;
      top: 15px;
      left: 9px;
      background: #ff7d18;
      border-radius: 10px 15px 15px 0px;
      font-size: 12px;
      text-align: center;
      line-height: 25px;
      color: #ffffff;
    }
    .Relevancyblue {
      position: absolute;
      width: 83px;
      height: 25px;
      top: 15px;
      left: 9px;
      background: #146aff;
      border-radius: 10px 15px 15px 0px;
      font-size: 12px;
      text-align: center;
      line-height: 25px;
      color: #ffffff;
    }
    .storeCenter_item_top {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      box-sizing: border-box;
      padding-bottom: 14px;
      position: relative;
      background: #ffffff;
      padding: 16px 20px 10px 10px;
      border-radius: 8px;

      .storeCenter_item_top_left {
        display: flex;
        flex-direction: row;
        height: 120.73px;
        width: 40%;

        .banner_box {
          position: relative;
          width: 175.55px;
          height: 115.73px;
          padding-right: 10px;
          img {
            width: 170.55px;
            height: 117.73px;
            border-radius: 4px;
            margin-right: 18px;
          }
          .activity_state {
            position: absolute;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 34px;
            text-align: center;
            color: #ffffff;
            top: -10px;
            left: -9px;
            width: 78px;
            height: 34px;
            border-radius: 5px 15px 15px 0;
          }
          .not_started {
            background: #4e93fb;
          }
          .have_in_hand {
            background: #ff7d18;
          }
          .closed {
            background: #999999;
          }
        }

        .storeCenter_item_top_left_flex {
          flex: 1;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .generalVersion {
            background: #448aff;
            border-radius: 2px;
            padding: 5px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            /* identical to box height */
            margin: 0 10px;
            color: #ffffff;
          }
          .mark_box {
            display: flex;
            img {
              width: 14px;
              // height: 14px;
              margin-right: 6px;
              color: #6e6e6e;
            }
            & > div {
              margin-left: 10px;
            }
          }
          .img_commer {
            display: flex;
            border: 1px solid #146aff;
            border-radius: 39px;
            .name {
              font-size: 12px;
              line-height: 20px;
              color: #146aff;
            }
            .color_img {
              background: #146aff;
              height: 20px;
              width: 25px;
              border-radius: 20px;
              position: relative;
              left: -10px;
              img {
                width: 13px;
                height: 13px;
                position: relative;
                left: 5px;
                top: 2px;
              }
            }
          }
          .img_commers {
            display: flex;
            border: 1px solid #ff9c00;
            border-radius: 39px;
            margin: 0px 10px;
            .name {
              font-size: 12px;
              line-height: 20px;
              color: #ff9c00;
            }
            .color_img {
              background: #ff9c00;
              height: 20px;
              width: 25px;
              border-radius: 20px;
              position: relative;
              left: -10px;
              img {
                width: 13px;
                height: 13px;
                position: relative;
                left: 5px;
                top: 2px;
              }
            }
          }

          h4 {
            color: #1f2d3d;
            font-size: 26px;
            font-weight: 500;
            margin: 0;
          }

          .storeCenter_item_top_left_flex_tag {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-content: center;
            padding-top: 13px;
            margin-bottom: 1px;
            width: 500px;
            span {
              box-sizing: border-box;
              display: block;
              font-size: 14px;
              font-weight: 400;
              border-radius: 4px;
            }

            .office {
              border: 1px solid #559eff;
              color: #4e93fb;
              padding: 1px 6px;
              margin-right: 10px;
            }

            .support {
              border: 1px solid #fd523f;
              color: #fd523f;
              margin-left: 12px;
            }
          }

          .address {
            font-size: 14px;
          }
        }
      }
      .storeCenter_item_top_center {
        display: flex;
        width: 30%;
        .count_box {
          height: 80px;
          width: 80px;
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          cursor: pointer;
          & > div:nth-of-type(1) {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;

            color: #078bff;
          }
          & > div:nth-of-type(2) {
            margin-top: 20px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #333333;
          }
        }
      }
      .storeCenter_item_top_right {
        align-items: stretch;
        height: 100%;
        width: 20%;

        .po_imgqr {
          position: absolute;
          right: 0px;
          top: 0px;
          cursor: pointer;
          img {
            width: 50px;
            height: 50px;
          }
        }
        .pore_qr {
          position: relative;
          left: -200px;
          top: -20px;
          z-index: 999999 !important;

          .popover_img {
            display: flex;
            justify-content: center;
            img {
              width: 80px;
              height: 80px;
            }
          }
          .qr_top {
            display: flex;
            justify-content: center;
            color: #4e93fb;
          }
        }
        .operation_area {
          margin-top: 30px;
          display: flex;
          align-items: center;
          justify-content: right;
          min-width: 200px;

          .buttom_right {
            height: 36px;
            line-height: 1px;
          }
          .release {
            display: inline-block;
            width: 89px;
            height: 36px;
            background: #4e93fb;
            border: 1px solid #4e93fb;
            border-radius: 2px;
            color: #ffffff;
            cursor: pointer;
            font-size: 14px;
            text-align: center;
            line-height: 36px;
            margin-right: 20px;
          }
          .shelf {
            display: inline-block;
            width: 89px;
            height: 36px;
            font-size: 14px;
            text-align: center;
            line-height: 36px;
            background: #ff7d18;
            /* chengs */

            border: 1px solid #ff7d18;
            border-radius: 2px;
            cursor: pointer;
            color: #ffffff;
            margin-right: 20px;
          }
        }
        .details {
          text-align: right;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          color: #999999;
          margin-top: 5px;
        }
      }
      .pore_qr {
        position: relative;
        left: -200px;
        top: -20px;
        z-index: 999999 !important;

        .popover_img {
          display: flex;
          justify-content: center;
          img {
            width: 80px;
            height: 80px;
          }
        }
        .qr_top {
          display: flex;
          justify-content: center;
          color: #4e93fb;
        }
      }
    }
  }
  .edit {
    color: #4e93fb;
    cursor: pointer;
  }
  .frozen {
    color: #fd5469;
    cursor: pointer;
    margin-left: 20px;
  }
  /deep/ .el-radio-button__inner {
    border: none;
    margin-right: 12px;
    border-radius: 4px;
  }
}
</style>
